.illustration {
  --illustration-gradient-stop-1: #3eaee6;
  --illustration-gradient-stop-2: #41d0cb;
}

.cls1 {
  fill: url(#linear-gradient);
}

.cls2 {
  fill: #c2c9c8;
}

.cls3 {
  fill: #31772f;
}

.cls4 {
  fill: #0a2b0a;
}

.cls5 {
  fill: #175814;
}

.cls6 {
  fill: #95c692;
}

.cls7 {
  fill: #589854;
}

.cls8 {
  fill: #4b4e51;
}

.cls9 {
  fill: #fff;
  opacity: 0.6;
}

/**
 * The blue version is a bit wonky...it looks like the colors are inverted, and we'd need to set the gradient stops here.
 * The classnames attched to each path in the blue SVG were changed slighly (cls4 and cls5 were swapped, cls3=cls6)
 * Asking design if we can get a more consistent SVG export from Figma
 */

.blue .cls1 {
  fill: url(#linear-gradient);
}

.blue .cls2 {
  fill: #c2c9c8;
}

.blue .cls6 {
  fill: #a3c0f7;
}

.blue .cls5 {
  fill: #2155b7;
}

.blue .cls4 {
  fill: #062667;
}

.blue .cls3 {
  fill: #1044a9;
}

.blue .cls7 {
  fill: #3776ed;
}

.blue .cls8 {
  fill: #4b4e51;
}
