@import "common/_deprecated/styles/breakpoints.module.scss";
@import "common/_deprecated/styles/fonts.module.scss";

.flex-xy-plot-container {
  width: 100%;
  height: 250px;
  @include font-size-small;

  @include breakpoint("mobile") {
    font-size: 11px; // intentionally smaller than usual
  }
}

.rv-xy-plot {
  cursor: crosshair;
  position: relative;

  .rv-crosshair {
    position: absolute;
    @include font-size-xsmall;
    pointer-events: none;
  }

  .rv-crosshair__line {
    width: 0px;
  }

  .rv-crosshair__inner {
    position: absolute;
    text-align: left;
    top: 0;
  }

  .rv-crosshair__inner__content {
    border-radius: 4px;
    background: var(--theme-colors-mono900);
    color: var(--theme-colors-mono100);
    @include font-size-small;
    padding: 7px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }

  .rv-crosshair__title {
    font-weight: 700;
    white-space: nowrap;
  }

  .rv-crosshair__item {
    white-space: nowrap;
  }
}

.rv-xy-plot__inner {
  overflow: visible;
}
