.cls1 {
  fill: #fff;
  opacity: 0.6;
}

.cls1,
.cls2,
.cls3,
.cls4,
.cls5 {
  stroke-width: 0px;
}

.cls2 {
  fill: #4b4e51;
}

.cls3 {
  fill: #c1c8c7;
}

.cls4 {
  fill: #ffa814;
}

.cls5 {
  fill: #ffc66d;
}
