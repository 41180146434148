.cls1 {
  fill: #fff;
  opacity: 0.6;
}

.cls1,
.cls2,
.cls3,
.cls4,
.cls5 {
  stroke-width: 0px;
}

.cls2 {
  fill: #95c692;
}

.cls3 {
  fill: #175814;
}

.cls4 {
  fill: #0a2b0a;
}

.cls5 {
  fill: #c2c9c8;
}
