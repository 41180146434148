/**
 * Valon Lato
 *
 * @see https://web.dev/font-best-practices/#use-woff2
 */
@font-face {
  font-family: "Valon Lato";
  src: url("woff2/ValonLato-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Valon Lato";
  src: url("woff2/ValonLato-RegularItalic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Valon Lato";
  src: url("woff2/ValonLato-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Valon Lato";
  src: url("woff2/ValonLato-BoldItalic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Valon Lato";
  src: url("woff2/ValonLato-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Valon Lato";
  src: url("woff2/ValonLato-BlackItalic.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
