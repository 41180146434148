.cls1 {
  fill: #fffdee;
  stroke: #fcb744;
}

.cls1,
.cls2,
.cls3 {
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.cls2,
.cls3 {
  stroke: #31772f;
}

.cls2,
.cls4,
.cls5 {
  fill: #fff;
}

.cls6 {
  filter: url(#dropshadow1);
}

.cls3 {
  fill: none;
}

.cls7,
.cls4 {
  opacity: 0.6;
}

.cls7,
.cls4,
.cls8,
.cls9,
.cls10,
.cls11,
.cls12,
.cls13,
.cls5,
.cls14,
.cls15,
.cls16 {
  stroke-width: 0px;
}

.cls7,
.cls9 {
  fill: #f2f3f4;
}

.cls8 {
  fill: #4b4e51;
  opacity: 0.3;
}

.cls10 {
  fill: #4c4e51;
}

.cls11 {
  fill: #3d3f41;
}

.cls12 {
  fill: #c0c7c6;
}

.cls13 {
  fill: #806530;
}

.cls14 {
  fill: #ffa814;
}

.cls15 {
  fill: #ffc66d;
}

.cls16 {
  fill: #ffd699;
}
