@import "common/_deprecated/styles/breakpoints.module.scss";
@import "common/_deprecated/styles/fonts.module.scss";

.errorLayout {
  margin-bottom: 40px;
}

.container {
  margin-top: 50px;
  text-align: center;
}
.icon {
  @include font-size-xlarge;
  margin-bottom: 24px;

  &.hasAction {
    cursor: pointer;
  }
}

.image {
  margin: 0 auto;
  border-radius: 50%;
  object-fit: cover;
  width: 250px;
  height: 250px;
  margin-bottom: 24px;

  @include breakpoint("mobile") {
    width: 180px;
    height: 180px;
    margin-bottom: 12px;
  }
}

.title {
  @include font-size-normal;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 8px;
}

.contents {
  @include font-size-small;
  color: var(--theme-colors-mono600, #6f777a);
}

.errorCode {
  color: var(--theme-colors-mono600, #6f777a);
}

.link {
  color: var(--theme-colors-link-primary-text, #4477eb);
  font-weight: 700;
}
