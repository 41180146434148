@import "common/_deprecated/styles/fonts.module.scss";

/* System-wide defaults. */
html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body,
button,
html,
input,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 400;
  margin: 0;
}

body {
  line-height: 1.5;
  color: var(--theme-colors-mono900);
}

html {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;
  text-size-adjust: 100%;
}

img {
  height: auto;
  max-width: 100%;
}

a {
  color: var(--theme-colors-link-primary-text);
  font-weight: 500;
  transition: 0.2s color;
  text-decoration: none;

  &:hover {
    color: var(--theme-colors-link-primary-text-hover);
  }

  &.destructive {
    color: var(--theme-colors-negative600);

    &:hover {
      color: var(--theme-colors-negative600);
    }
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

p {
  @include font-size-normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

td:not([align]),
th:not([align]) {
  text-align: left;
}

// this is necessary to display Plaid's Link properly since sanitize.css overrides the height of iframe's to be auto
iframe {
  height: 100%;
}

/**
  * Add styles to apply from useAddBodyClass here.
  */

// When the body has fullModalOpen...
.onboarding-bulk-edit-tool-open,
.build-claim-modal-open,
.modal-open {
  // ...apply negative z-index to command bar.
  #commandbar-inline-root-header,
  #commandbar-inline-root-sc-header {
    z-index: 0;
  }
}
