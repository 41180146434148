@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 1440px) {
      @content;
    }
  } @else if $point == laptop {
    @media (min-width: 1025px) {
      @content;
    }
  } @else if $point == laptopSm {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $point == tablet {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $point == tabletSm {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $point == mobile {
    @media (max-width: 320px) {
      @content;
    }
  }
}
