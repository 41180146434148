.cls1 {
  opacity: 0.7;
}

.cls1,
.cls2,
.cls3,
.cls4,
.cls5,
.cls6,
.cls7 {
  strokewidth: 0px;
}

.cls1,
.cls3,
.cls6 {
  fill: #a3c0f7;
}

.cls2 {
  fill: #c2c9c8;
}

.cls2,
.cls3,
.cls8 {
  opacity: 0.81;
}

.cls9 {
  filter: url(#dropshadow1);
}

.cls10 {
  opacity: 0.6;
}

.cls4 {
  fill: #3776ed;
}

.cls5 {
  fill: #2155b7;
}

.cls7 {
  fill: #fff;
}
