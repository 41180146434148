.cls1 {
  font-size: 270.25px;
}

.cls1,
.cls2 {
  font-family: ValonLatoBlack, "Valon Lato";
}

.cls1,
.cls2,
.cls3 {
  fill: #fff;
}

.cls2 {
  font-size: 270.25px;
}

.cls3 {
  opacity: 0.6;
}

.cls3,
.cls4,
.cls5,
.cls6 {
  stroke-width: 0px;
}

.cls4 {
  fill: #95c692;
}

.cls5 {
  fill: #0a2b0a;
}

.cls6 {
  fill: #31772f;
}
