.cls1 {
  fill: #ffb944;
}

.cls2 {
  fill: #ffa914;
}

.cls3 {
  fill: #806530;
}

.cls4 {
  fill: #c2c9c8;
}

.cls5 {
  fill: #4b4e51;
}

.cls6 {
  fill: #fff;
  opacity: 0.6;
}
