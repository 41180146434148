.cls1 {
  clip-path: url(#clippath);
}

.cls2 {
  fill: none;
}

.cls2,
.cls3,
.cls4,
.cls5,
.cls6,
.cls7,
.cls8,
.cls9,
.cls10,
.cls11,
.cls12,
.cls13 {
  stroke-width: 0px;
}

.cls3 {
  fill: url(#linear-gradient);
}

.cls4 {
  opacity: 0.8;
}

.cls4,
.cls8 {
  fill: #fff;
}

.cls5 {
  fill: url(#New_Gradient_Swatch_copy_2);
}

.cls6 {
  fill: url(#linear-gradient-2);
}

.cls7 {
  fill: url(#linear-gradient-3);
}

.cls8 {
  opacity: 0.6;
}

.cls9 {
  fill: #4b4e51;
}

.cls10 {
  fill: #c2c9c8;
}

.cls11 {
  fill: #ff8355;
}

.cls12 {
  fill: #ed5a1e;
}

.cls13 {
  fill: #ffa914;
}
