.cls1 {
  fill: #ffb944;
}

.cls2 {
  fill: #ffd79a;
}

.cls3 {
  fill: #806530;
}

.cls4 {
  fill: #ca8300;
}

.cls5 {
  fill: #4b4e51;
}

.cls6 {
  fill: #fff;
  opacity: 0.6;
}

.green .cls4 {
  fill: #175814;
}

.green .cls3 {
  fill: #0d420e;
}

.green .cls2 {
  fill: #95c692;
}

.green .cls1 {
  fill: #589854;
}

.green .cls5 {
  fill: #4b4e51;
}

.green .cls6 {
  fill: #fff;
  opacity: 0.6;
}
