.cls1 {
  fill: none;
}

.cls1,
.cls2 {
  opacity: 0.6;
}

.cls3,
.cls2 {
  fill: #fff;
}

.cls4 {
  fill: #ffb944;
}

.cls5 {
  fill: #ffa914;
}

.cls6 {
  fill: #ca8300;
}
