.cls1 {
  clip-path: url(#clippath);
}

.cls2 {
  fill: none;
}

.cls2,
.cls3,
.cls4,
.cls5,
.cls6,
.cls7 {
  stroke-width: 0px;
}

.cls3 {
  fill: #fff;
  opacity: 0.6;
}

.cls4 {
  fill: #4b4e51;
}

.cls5 {
  fill: #ffa814;
}

.cls6 {
  fill: #ffb844;
}

.cls7 {
  fill: #ffd699;
}
