.cls1 {
  fill: #c2c9c8;
}

.cls2 {
  fill: #589854;
}

.cls3 {
  fill: #fff;
  opacity: 0.6;
}

.cls1,
.cls2,
.cls3 {
  stroke-width: 0px;
}

.blue .cls2 {
  fill: #3776ed;
}
