.cls1 {
  fill: none;
}

.cls1,
.cls2 {
  opacity: 0.6;
}

.cls3,
.cls2 {
  fill: #fff;
}

.cls4 {
  fill: #a3c0f7;
}

.cls5 {
  fill: #062667;
}

.cls6 {
  fill: #1044a9;
}

.cls7 {
  fill: #3776ed;
}

.cls8 {
  fill: #4b4e51;
}

.green .cls1 {
  fill: none;
}

.green .cls1,
.green .cls2 {
  opacity: 0.6;
}

.green .cls3,
.green .cls2 {
  fill: #fff;
}

.green .cls6 {
  fill: #175814;
}

.green .cls5 {
  fill: #0d420e;
}

.green .cls4 {
  fill: #95c692;
}

.green .cls7 {
  fill: #589854;
}

.green .cls8 {
  fill: #4b4e51;
}
