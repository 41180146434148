.cls1 {
  filter: url(#drop-shadow1);
}

.cls2,
.cls3 {
  opacity: 0.6;
}

.cls2,
.cls3,
.cls4,
.cls5,
.cls6,
.cls7,
.cls8,
.cls9 {
  stroke-width: 0px;
}

.cls2,
.cls5 {
  fill: #f2f3f4;
}

.cls3,
.cls9 {
  fill: #fff;
}

.cls4 {
  fill: #4b4e51;
  opacity: 0.3;
}

.cls6 {
  fill: #4c4e51;
}

.cls7 {
  fill: #c0c7c6;
}

.cls8 {
  fill: #31772f;
}
