.cls1 {
  opacity: 0.7;
}

.cls1,
.cls2,
.cls3,
.cls4,
.cls5,
.cls6,
.cls7,
.cls8,
.cls9,
.cls10,
.cls11,
.cls12,
.cls13,
.cls14,
.cls15,
.cls16,
.cls17,
.cls18,
.cls19,
.cls20,
.cls21,
.cls22,
.cls23,
.cls24,
.cls25,
.cls26,
.cls27,
.cls28 {
  stroke-width: 0px;
}

.cls1,
.cls16 {
  fill: #c1c8c7;
}

.cls2 {
  fill: url(#linear-gradient);
}

.cls29,
.cls4 {
  opacity: 0.6;
}

.cls3 {
  fill: url(#linear-gradient-2);
}

.cls4,
.cls23 {
  fill: #fff;
}

.cls5 {
  fill: #f89f55;
}

.cls6 {
  fill: #f8ab8d;
}

.cls7 {
  fill: #f2f3f4;
}

.cls8 {
  fill: #f9a71c;
}

.cls9 {
  fill: #4b71b6;
}

.cls10 {
  fill: #4c4e51;
}

.cls11 {
  fill: #389e47;
}

.cls12 {
  fill: #222e63;
}

.cls13 {
  fill: #204a9e;
}

.cls14 {
  fill: #a6bde2;
}

.cls15 {
  fill: #b53a25;
}

.cls17 {
  fill: #32763a;
}

.cls18 {
  fill: #bb5c27;
}

.cls19 {
  fill: #7e2818;
}

.cls20 {
  fill: #806531;
}

.cls21 {
  fill: #82411d;
}

.cls22 {
  fill: #f08021;
}

.cls24 {
  fill: #ec5b24;
}

.cls25 {
  fill: #fcb744;
}

.cls26 {
  fill: #ffd69a;
}

.cls27 {
  fill: #fbbc88;
}

.cls28 {
  fill: #f48258;
}

.st0 {
  fill: #222e63;
}

.st1 {
  fill: #ffd69a;
}

.st2 {
  fill: #fcb744;
}

.st3 {
  fill: #4b71b6;
}

.st4 {
  fill: #a6bde2;
}

.st5 {
  fill: #f48258;
}

.st6,
.st7 {
  fill: #fff;
}

.st8 {
  fill: #32763a;
}

.st9 {
  fill: #c1c8c7;
  opacity: 0.7;
}

.st9,
.st7 {
  isolation: isolate;
}

.st10,
.st7 {
  opacity: 0.6;
}

.st11 {
  fill: #f8ab8d;
}

.st12 {
  fill: #389e47;
}

.st13 {
  fill: #806531;
}

.st14 {
  fill: #7e2818;
}

.st15 {
  fill: #4c4e51;
}

.st16 {
  fill: #f2f3f4;
}

.st17 {
  fill: #b53a25;
}

.st18 {
  fill: #204a9e;
}
