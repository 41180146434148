@import "common/_deprecated/styles/breakpoints.module.scss";

// import font faces
@import "common/_deprecated/styles/fonts/valon-lato/valon-lato.scss";

// Standardized font sizes for general use

@mixin font-size-xxxlarge {
  // Standard body text
  font-size: 62px;

  @include breakpoint("mobile") {
    font-size: 30px;
  }
}

@mixin font-size-xxlarge {
  // Standard body text
  font-size: 42px;

  @include breakpoint("mobile") {
    font-size: 26px;
  }
}

@mixin font-size-xlarge {
  font-size: 30px;

  @include breakpoint("mobile") {
    font-size: 22px;
  }
}

@mixin font-size-large {
  font-size: 22px;

  @include breakpoint("mobile") {
    font-size: 18px;
  }
}

@mixin font-size-normal {
  font-size: 16px;

  @include breakpoint("mobile") {
    font-size: 14px;
  }
}

@mixin font-size-small {
  font-size: 15px;

  @include breakpoint("mobile") {
    font-size: 14px;
  }
}

@mixin font-size-xsmall {
  font-size: 13px;

  @include breakpoint("mobile") {
    font-size: 12px;
  }
}

// Sizes for specific use cases

@mixin font-size-input {
  // Do not make this less than 16px, as otherwise that will make the screen
  // zoom in on focus within the input.
  font-size: 18px;
}

@mixin font-style-balance {
  // For showing loan balance, etc. in a prominent way

  font-size: 38px;
  color: var(--theme-colors-mono1000);
  line-height: 1;
  font-weight: 700;

  @include breakpoint("mobile") {
    font-size: 32px;
  }
}
