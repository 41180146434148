.clickToCopy {
  cursor: default;
  padding-right: calc(1em + var(--theme-sizing-spacing-small-xxx));
  display: flex;
  column-gap: var(--theme-sizing-spacing-small-xxx);
  align-items: flex-start;
}

.linkKind {
  align-items: baseline;
}

.clickToCopy a {
  cursor: pointer;
}

.attributeToCopy {
  transition: background-color 0.2s ease-in-out;
}

.clickToCopy:hover .attributeToCopy {
  background-color: var(--theme-colors-accent-primary300);
}

.tooltip {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  display: flex !important; /* !important needed to override baseweb */
  align-items: baseline;
}

.clickToCopy:hover .tooltip {
  opacity: 1;
}

/**
 * We'll want the icon to inherit the size of the Click to Copy component.
 * Since the icon is 1em, it'll inherit from the button.
 */
.clickToCopy button {
  font-size: 1em;
  color: currentColor;
  position: relative;
}
